.presetcontainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1em;
}


.presetHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}


.createNewPreset {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 2em);
}

.presetitemlist {
    width: 100%;
    height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
}