@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  display: flex;
  width:100%;
  justify-content: center;
}
.listContainer {
  width: 95%;
  max-width: 1000px;
}

.listContainer > *{
  margin-top: 1em
}


.clearBtn {
  width: 100%;
  background-color:transparent;
  border: 3px black solid;
  border-radius: 1em;
}
body {
  font-family: 'Montserrat', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.countContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    /* padding: 0 .75em; */
    border-radius: 1em;
    height: 3em;
    overflow: hidden;

}


.mainCountContainer {
    text-align: center;
}

.count {
    margin: 0 1em
}

button {
    background-color: rgba(0, 0, 0, 0.116);
    height: 4em;
    width: 4em;
    cursor: pointer;
    
    border: none
}

button:hover,
button:focus {
    background-color: rgba(0, 0, 0, 0.336);
}







.label {
    font-weight: bold;
    
}
.presetItemContainer {
    position: relative;
    display: flex;
    margin: 1em 0;
    cursor: pointer;
    align-items: center;
    width: 100%;
    /* background-color: red; */
}


.presetItemContainer:hover {
    display: flex;
    margin-left: .5em;
    
}

.presetItemTitle {
    font-size: .9em;
    margin-right: 1em;
    text-overflow:clip;
    word-wrap: break-word;
    white-space:pre-line;
    max-width: 75%;
}

.presetItemDesc {
    font-size: .6em;
    color: gray;
    max-width: 10%;
}

.presetItemDelete {
    /* visibility:visible; */
    position:absolute;
    right: 0px;
    font-size: .7em;
}

.presetItemContainer:hover .presetItemDelete {
    visibility: visible;
}

@media screen and (max-width: 400px) {
    .presetItemDesc {
        display: none;
    }
}
.presetcontainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1em;
}


.presetHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}


.createNewPreset {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 2em);
}

.presetitemlist {
    width: 100%;
    height: 75%;
    overflow-y: scroll;
    overflow-x: hidden;
}
