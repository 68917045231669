@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;800;900&display=swap);

body {
  font-family: 'Montserrat', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.countContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    /* padding: 0 .75em; */
    border-radius: 1em;
    height: 3em;
    overflow: hidden;

}


.mainCountContainer {
    text-align: center;
}

.count {
    margin: 0 1em
}

button {
    background-color: rgba(0, 0, 0, 0.116);
    height: 4em;
    width: 4em;
    cursor: pointer;
    
    border: none
}

button:hover,
button:focus {
    background-color: rgba(0, 0, 0, 0.336);
}







.label {
    font-weight: bold;
    
}