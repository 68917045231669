.presetItemContainer {
    position: relative;
    display: flex;
    margin: 1em 0;
    cursor: pointer;
    align-items: center;
    width: 100%;
    /* background-color: red; */
}


.presetItemContainer:hover {
    display: flex;
    margin-left: .5em;
    
}

.presetItemTitle {
    font-size: .9em;
    margin-right: 1em;
    text-overflow:clip;
    word-wrap: break-word;
    white-space:pre-line;
    max-width: 75%;
}

.presetItemDesc {
    font-size: .6em;
    color: gray;
    max-width: 10%;
}

.presetItemDelete {
    /* visibility:visible; */
    position:absolute;
    right: 0px;
    font-size: .7em;
}

.presetItemContainer:hover .presetItemDelete {
    visibility: visible;
}

@media screen and (max-width: 400px) {
    .presetItemDesc {
        display: none;
    }
}