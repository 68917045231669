.App{
  display: flex;
  width:100%;
  justify-content: center;
}
.listContainer {
  width: 95%;
  max-width: 1000px;
}

.listContainer > *{
  margin-top: 1em
}


.clearBtn {
  width: 100%;
  background-color:transparent;
  border: 3px black solid;
  border-radius: 1em;
}